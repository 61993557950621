import React from "react";
import theme from "theme";
import { Theme, Text, Box, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | DriveSafe Hukuk Hizmetleri
			</title>
			<meta name={"description"} content={"Sürüşle ilgili sıkıntılarınızı zafere dönüştürme konusunda uzmanlaştığımız DriveSafe Legal Services'e hoş geldiniz. "} />
			<meta property={"og:title"} content={"Home | DriveSafe Hukuk Hizmetleri"} />
			<meta property={"og:description"} content={"Sürüşle ilgili sıkıntılarınızı zafere dönüştürme konusunda uzmanlaştığımız DriveSafe Legal Services'e hoş geldiniz. "} />
			<meta property={"og:image"} content={"https://koala-hust.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://koala-hust.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://koala-hust.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://koala-hust.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://koala-hust.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://koala-hust.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://koala-hust.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="160px 0 170px 0" md-padding="80px 0 90px 0" quarkly-title="Hero-9">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="7px 20px 7px 20px"
					margin="0px 0px 30px 0px"
					border-radius="25px"
					border-width="1px"
					border-style="solid"
					border-color="#a6acb1"
					sm-flex-direction="column"
					justify-content="center"
				>
					<Text
						margin="0px 10px 0px 0px"
						color="#50555a"
						font="normal 300 16px/1.5 --fontFamily-sans"
						sm-margin="0px 0 5px 0px"
						sm-text-align="center"
					>
						Yoldaki Tümseklerle Bırakın DriveSafe Hukuk Hizmetleri Başa Çıksın
					</Text>
				</Box>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
				>
					DriveSafe Hukuk Hizmetleri
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#50555a"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Sürüşle ilgili sıkıntılarınızı zafere dönüştürme konusunda uzmanlaştığımız DriveSafe Legal Services'e hoş geldiniz. Trafik yasalarını derinlemesine anlayarak ve haklarınızı koruma taahhüdümüzle, hız cezaları, park ihlalleri ve yolla ilgili diğer yasal zorluklar için uzman hukuki yardım sunmak için buradayız.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="--color-primary"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
						hover-background="rgba(63, 36, 216, 0)"
						hover-color="--darkL1"
						href="/contact"
						type="link"
						text-decoration-line="initial"
					>
						İletişim
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
							Neden DriveSafe Hukuk Hizmetlerini Seçmelisiniz?
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Trafik Hukukunda Uzmanlık
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Ekibimiz, trafik düzenlemelerinin inceliklerini iyi bilmektedir ve bilgili ve etkili bir temsil sağlamaktadır.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Hızlı ve Verimli Hizmet
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Özellikle trafik ihlalleri söz konusu olduğunda zamanın önemini anlıyoruz. Kolaylaştırılmış süreçlerimiz, sorunlarınızı derhal çözmeyi amaçlamaktadır.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Müşteri Merkezli Yaklaşım
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									İç huzurunuz bizim önceliğimizdir. Dinleriz, anlarız ve yoldayken çıkarlarınızı korumak için strateji geliştiririz.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Şeffaf Fiyatlandırma
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Gizli maliyetler veya sürprizler yok. Ücretlerimizle ilgili açık ve net iletişime inanıyoruz.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="150px 0 150px 0"
			min-height="100vh"
			background="linear-gradient(180deg,--color-light 0%,transparent 86.7%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://koala-hust.com/img/1.jpg) 0% 0% /cover no-repeat scroll padding-box"
			lg-padding="90px 0 90px 0"
			quarkly-title="404-2"
		>
			<Override slot="SectionContent" max-width="1220px" justify-content="flex-start" />
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text
					color="--dark"
					margin="8px 0px 16px 0px"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					font="--headline2"
					letter-spacing="-0.025em"
					lg-margin="0px 0px 16px 0px"
				>
					Güvenle Sürün
				</Text>
				<Text
					lg-width="80%"
					font="--lead"
					color="--dark"
					margin="0px 0px 36px 0px"
					text-align="center"
					lg-margin="0px 0px 24px 0px"
				>
					Yoldaki Tümseklerle Bırakın DriveSafe Hukuk Hizmetleri Başa Çıksın
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});